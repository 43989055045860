import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setCheckedNoCabinBaggage,
  setCheckedCabinBaggage,
} from "../../../store/cabinBaggageSlice/slice";
import { affiliates } from "../../../common/enums/affiliates";
import { GetAffiliate } from "../../../services/general.service";
import { cabin_luggage_icon_warning as CabinLuggageWarningIconTransavia } from "../../../assets/icons/transavia/index";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { ancillaryTypes } from "../../../common/constants/ancillaryTypes";
import { LocalStorageService } from "../../../services/local-storage.service";
import CrossIcon from "../../../assets/icons/klm/klm_cancel_extras_baggage.svg";
import CrossIconTransavia from "../../../assets/icons/transavia/transavia_cross_icon.svg";

const NoCabinBaggage = ({
  luggageData,
  onCabinBaggageSelectionChanged,
  cabinBaggageChecked,
  setCabinBaggageChecked,
}) => {
  const dispatch = useDispatch();
  const hasError = useSelector((state) => state.cabinBaggage.hasError);
  const NocabinBaggageData = luggageData[0]?.fields;

  const handleChange = () => {
    setCabinBaggageChecked(false);
    dispatch(setCheckedNoCabinBaggage(true));
    dispatch(setCheckedCabinBaggage(false));
    dispatch(setError(false));

    localStorage.setItem(
      LocalStorageEnum.CHECKED_NO_CABIN_BAGGAGE,
      JSON.stringify(cabinBaggageChecked)
    );

    if (cabinBaggageChecked) {
      handleNoCabinBaggageSelection();
    }
  };

  const handleNoCabinBaggageSelection = () => {
    const requestData = {
      type: ancillaryTypes.CabinBaggage,
      travellerIdentifier: "",
      ancillaryOptionCode: "",
    };
    setCabinBaggageChecked(false);
    onCabinBaggageSelectionChanged(requestData);
  };

  const displayCabinLuggageWarningIcon = () => {
    return GetAffiliate() === affiliates.klm ? (
      <img alt="KLMWarningIcon" src={CabinLuggageWarningIconTransavia} />
    ) : (
      <img alt="TransaviaWarningIcon" src={CabinLuggageWarningIconTransavia} />
    );
  };

  const displayCrossIconCanelBaggage = () => {
    return GetAffiliate() === affiliates.klm ? (
      <img alt="KLMCrossIcon" src={CrossIcon} />
    ) : (
      <img alt="TransaviaCrossIcon" src={CrossIconTransavia} />
    );
  };

  useEffect(() => {
    if (cabinBaggageChecked) {
      dispatch(setCheckedNoCabinBaggage(false));
    }
  }, [cabinBaggageChecked]);

  return (
    <div
      className={`no-baggage-container ${
        !cabinBaggageChecked ? "checked" : ""
      }`}
      onClick={handleChange}
    >
      <div className="no-baggage-card-info">
        <div className="no-baggage-radio-button">
          <input
            type="radio"
            checked={!cabinBaggageChecked}
            onChange={handleChange}
            name="no-baggage-radio"
            aria-label="No Cabin Baggage"
            className="radio-button-no-cabin"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="no-baggage-text-box">
          <p className="no-baggage-title">
            {luggageData && luggageData[0]?.fields?.noCabinHeader}
          </p>
          <p className="no-baggage-subtitle">
            {luggageData && luggageData[0]?.fields?.noCabinDescription}
          </p>
        </div>
        <div className="remove-icon-box">{displayCrossIconCanelBaggage()}</div>
      </div>
      {!cabinBaggageChecked && (
        <div className="no-baggage-card-warning">
          <div className="no-baggage-card-warning-icon">
            {displayCabinLuggageWarningIcon()}
          </div>
          <div className="no-baggage-card-warning-info">
            {NocabinBaggageData && NocabinBaggageData?.noCabinWarningMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default NoCabinBaggage;
