import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PassengerDetailsFormFields } from "../../../common/constants/passengerDetailsFormFields";
import { parseContentfulDynamicContent } from "../../../services/contentful.service";
import { setIsCabinBaggageSelected } from "../../../store/cabinBaggageSlice/slice";
import { setError } from "../../../store/cabinBaggageSlice/slice";
import CabinErrorComponent from "./cabin-error-message.component";

const CabinTripComponent = ({
  luggageData,
  cabinBaggageChecked,
  baggageDetails,
  contentfulExtrasPage,
  onCabinBaggageSelectionChanged,
}) => {
  const dispatch = useDispatch();
  const isCabinBaggageSelected = useSelector(
    (state) => state.cabinBaggage.isCabinBaggageSelected
  );
  const hasError = useSelector((state) => state.cabinBaggage.hasError);
  const [cabinBaggageOptions, setCabinBaggageOptions] = useState([]);
  const [selectedPassengers, setSelectedPassengers] = useState({});
  const [isAnyPassengerSelected, setIsAnyPassengerSelected] = useState(false);

  const getTravellerTypeDescription = (travellerType, index) => {
    switch (travellerType) {
      case PassengerDetailsFormFields.ADULT:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
      case PassengerDetailsFormFields.CHILD:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.childTravelerTitle,
          ++index
        );
      default:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
            contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
    }
  };

  const handleChange = (event, passenger) => {
    const travellerIdentifier = passenger.travellerIdentifier;
    const isSelected = !passenger.isSelected;
    const newSelectedPassengers = {
      ...selectedPassengers,
      [travellerIdentifier]: isSelected,
    };
    setSelectedPassengers(newSelectedPassengers);

    dispatch(setError(false));

    if (passenger) {
      const optionCode = isSelected ? passenger.ancillaryOptionCode : "";
      const requestData = {
        travellerIdentifier,
        ancillaryOptionCode: optionCode,
      };

      onCabinBaggageSelectionChanged(requestData);
    }
  };

  useEffect(() => {
    setCabinBaggageOptions(baggageDetails.cabinBaggageProducts);
    setIsAnyPassengerSelected(
      baggageDetails.cabinBaggageProducts.some(
        (baggagePerPassenger) => baggagePerPassenger.isSelected
      )
    );
    dispatch(setIsCabinBaggageSelected(isAnyPassengerSelected));
  }, [baggageDetails]);

  useEffect(() => {
    if (isCabinBaggageSelected) {
      dispatch(setError(false));
    }
  }, [isCabinBaggageSelected]);

  useEffect(() => {
    const updatedPassengers = {};
    cabinBaggageOptions.forEach((baggagePerPassenger) => {
      if (baggagePerPassenger.ancillaryOptionCode === "") {
        updatedPassengers[baggagePerPassenger.travellerIdentifier] = false;
      }
    });

    setSelectedPassengers((prev) => ({ ...prev, ...updatedPassengers }));
  }, []);

  return (
    <>
      {cabinBaggageChecked && cabinBaggageOptions.length > 0 && (
        <div>
          <CabinErrorComponent luggageData={luggageData} />
          <p className="cabin-luggage-trip-title">
            {luggageData && luggageData[0]?.fields?.cabinBaggageTripTitle}
          </p>
          <div
            className={`cabin-trip-baggage-container ${
              hasError ? "error" : ""
            }`}
          >
            {cabinBaggageOptions.map((baggagePerPassenger, index) => (
              <div className="cabin-baggage-item" key={index}>
                <label className="checkbox-label">
                  <div
                    className={`checkbox-container ${
                      baggagePerPassenger.isSelected ? "active" : "inactive"
                    } ${hasError ? "error" : ""}`}
                    onClick={() => handleChange(null, baggagePerPassenger)}
                  >
                    <div className="checkbox"></div>
                  </div>
                  <span className="form-cabin-label">
                    {getTravellerTypeDescription(
                      baggagePerPassenger.travellerType,
                      index
                    )}
                  </span>
                </label>
                <span className="price">
                  {baggagePerPassenger.price
                    ? `€${baggagePerPassenger.price}`
                    : "€0,-"}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CabinTripComponent;
