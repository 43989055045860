import { useEffect, useState } from "react";
import { TransferCardComponent } from "./transfers-card/transfers-card.component";
import "./transfers.component.scss";
import { Alert } from "@mui/material";

export const TransferComponent = ({
  contentfulTransfer,
  contentfulButtons,
  checkOutData,
  holidayKey,
  onSelectTransfer,
  isCarSelected,
  isWaitingForNetwork
}) => {
  const [transferInfo, setTransferInfo] = useState();
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    setTransferInfo(checkOutData?.additionalProductInfo?.transfersInfo);
  }, [checkOutData]);

  return (
    <div className="transfers-component">
      <h1 className="section-heading-text">
        {contentfulTransfer?.transferTitle}
      </h1>
      {isCarSelected && (
        <div className="option-no-available">
          <Alert className="transfer-alert" severity="info">
            {contentfulTransfer && contentfulTransfer?.transferNoSelectionAlert}
          </Alert>
        </div>
      )}
      <div className={`${isCarSelected ? "opacity-50" : ""}`}>
        <div className="transfer-description">
          {contentfulTransfer?.transferDescription}
        </div>
        <div className="transfers-card-container">
          {transferInfo?.transferOptions.map((option, index) => (
            <TransferCardComponent
              key={index}
              holidayKey={holidayKey}
              isCardSelected={false}
              transferOption={option}
              contentfulTransfer={contentfulTransfer}
              contentfulButtons={contentfulButtons}
              onSelectTransfer={onSelectTransfer}
              isWaitingForNetwork={isWaitingForNetwork}
              activeCard={activeCard}
              setActiveCard={setActiveCard} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};
