import { useSelector, useDispatch } from "react-redux";
import { Fragment, useState } from "react";
import { cabin_luggage as CabinLuggageIconKLM } from "../../../assets/icons/klm/index";
import { cabin_luggage as CabinLuggageIconTransavia } from "../../../assets/icons/transavia/index";
import { affiliates } from "../../../common/enums/affiliates";
import { setError } from "../../../store/cabinBaggageSlice/slice";
import { GetAffiliate } from "../../../services/general.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { LocalStorageService } from "../../../services/local-storage.service";
import {
  setCheckedCabinBaggage,
  setCheckedNoCabinBaggage,
  setIsCabinBaggageSelected,
} from "../../../store/cabinBaggageSlice/slice";
import CabinErrorComponent from "./cabin-error-message.component";
import NoCabinBaggage from "./no-cabin-baggage.component";
import "./cabin-luggage.component.scss";

export const CabinBaggageCard = ({
  luggageData,
  addBaggageHeader,
  cabinBaggageChecked,
  setCabinBaggageChecked,
  baggageDetails,
  allAncillaryOptionsEmpty,
  onCabinBaggageSelectionChanged,
  AddBaggageDetailsRenderer,
}) => {
  const dispatch = useDispatch();
  const affiliate = GetAffiliate();
  const hasError = useSelector((state) => state.cabinBaggage.hasError);
  const [cabinBaggageOptions, setCabinBaggageOptions] = useState(
    baggageDetails.cabinBaggageProducts
  );

  const showSoldOutWarning = baggageDetails?.showWarningNoAvailableCabinBaggage;

  const [checkApiStatus, setCheckApiStatus] = useState(false);

  if (!luggageData || luggageData.length === 0) return null;

  const cabinBaggageData = luggageData[0]?.fields;

  const handleChange = () => {
    setCabinBaggageChecked(true);
    dispatch(setCheckedCabinBaggage(true));
    dispatch(setCheckedNoCabinBaggage(false));
    dispatch(setIsCabinBaggageSelected(false));
    LocalStorageService.setLocalStorageItem(
      LocalStorageEnum.CHECKED_CABIN_BAGGAGE,
      cabinBaggageChecked
    );
  };

  const displayCabinBaggageConditionDetails = (data) => {
    if (data) {
      return data.split("\n").map((info, index) => (
        <Fragment key={index}>
          {info}
          <br />
        </Fragment>
      ));
    }
  };

  const displayCabinLuggageIcon = () => {
    return affiliate === affiliates.klm ? (
      <img alt="KLMIcon" src={CabinLuggageIconKLM} />
    ) : (
      <img alt="TransaviaIcon" src={CabinLuggageIconTransavia} />
    );
  };

  const cabinBaggagePrice = () => {
    if (cabinBaggageOptions) {
      return cabinBaggageOptions.price;
    }
  };

  const cabinBaggagePriceNumber = parseFloat(cabinBaggagePrice);

  return (
    <>
      {cabinBaggageData?.cabinBaggageConditions && (
        <div className="cabin-baggage-card-component">
          <div className="cabin-luggage-section-header">
            {luggageData && cabinBaggageData?.cabinBaggageHeader}
          </div>
          {!allAncillaryOptionsEmpty && !showSoldOutWarning && (
            <NoCabinBaggage
              luggageData={luggageData}
              onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
              cabinBaggageOptions={cabinBaggageOptions}
              cabinBaggageChecked={cabinBaggageChecked}
              setCabinBaggageChecked={setCabinBaggageChecked}
            />
          )}
          <div
            className={`cabin-baggage-card-info ${
              showSoldOutWarning ? "not-available" : ""
            } ${allAncillaryOptionsEmpty ? "no-cabin" : ""}`}
            onClick={
              !checkApiStatus &&
              !allAncillaryOptionsEmpty &&
              !showSoldOutWarning
                ? handleChange
                : undefined
            }
          >
            <div className="cabin-baggage-card-details">
              <div className="radio-button-cabin-luggage">
                <input
                  type="radio"
                  checked={cabinBaggageChecked}
                  onChange={handleChange}
                  value="cabin-baggage"
                  name="cabin-baggage-radio"
                  className={`radio-button-cabin ${hasError ? "error" : ""} ${
                    checkApiStatus ? "not-available-input" : ""
                  }`}
                  disabled={
                    checkApiStatus ||
                    allAncillaryOptionsEmpty ||
                    showSoldOutWarning
                  }
                />
              </div>
              <div className="cabin-baggage-card-description">
                <div className="cabin-baggage-card-title">
                  {addBaggageHeader || cabinBaggageData?.cabinBaggageLabel}
                </div>
                <div className="cabin-baggage-card-condition-details">
                  {AddBaggageDetailsRenderer
                    ? AddBaggageDetailsRenderer(
                        cabinBaggageData?.cabinBaggageConditions
                      )
                    : displayCabinBaggageConditionDetails(
                        cabinBaggageData?.cabinBaggageConditions
                      )}
                  {cabinBaggagePriceNumber > 0 && (
                    <div className="cabin-baggage-price">
                      {luggageData[0]?.fields?.fromTraveller}
                      <span className="price">€{cabinBaggagePrice}</span>
                      {luggageData[0]?.fields?.pricePerTraveller}
                    </div>
                  )}
                </div>
                {checkApiStatus && (
                  <div className="error-badge">
                    {luggageData && cabinBaggageData?.soldOutTripError}
                  </div>
                )}
              </div>
              <div className="cabin-baggage-card-img">
                {displayCabinLuggageIcon()}
              </div>
            </div>
            {showSoldOutWarning && (
              <div className="sold-out-btn">
                {luggageData && cabinBaggageData?.soldOutButton}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
