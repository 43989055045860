import Rating from "@mui/material/Rating";
import { styled } from "@mui/system";
import { GetAffiliate } from "../../../services/general.service";
import { affiliates } from "../../../common/enums/affiliates";
import StarIconKLM from "../../../assets/icons/klm/klm-star-summary.svg";
import StarIconTransavia from "../../../assets/icons/transavia/star-summary-transavia.svg";
import { useEffect, useState } from "react";

const StyledIconContainer = styled("span")({
  padding: "0 3px",
  marginTop: "10px",
  marginBottom: "10px",
});

export const HotelStarRatingComponent = ({starRating}) => {
  const [starRatingData, setStarRatingData] = useState(null);

  useEffect(() => {
    setStarRatingData(starRating);
  },[starRating]);
  
  return (
    <div className="hotel-star-rating-component">
      {GetAffiliate() === affiliates.klm ? (
        <Rating
          className="star-rating"
          readOnly
          value={starRatingData}
          precision={1}
          size="small"
          icon={<img src={StarIconKLM} alt="Star Icon" />}
          IconContainerComponent={StyledIconContainer}
        />
      ) : GetAffiliate() === affiliates.transavia ? (
        <Rating
          className="star-rating"
          readOnly
          value={starRatingData}
          precision={1}
          size="small"
          icon={<img src={StarIconTransavia} alt="Star Icon" />}
          IconContainerComponent={StyledIconContainer}
        />
      ) : null}
    </div>
  );
};
