import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasError: false,
  checkedNoCabinBaggage: true,
  checkedCabinBaggage: false,
  emptyAncillaryOptions: false,
  isCabinBaggageSelected: false, 
};

const cabinBaggageSlice = createSlice({
  name: 'cabinBaggage',
  initialState,
  reducers: {
    setError(state, action) {
      if (!state.emptyAncillaryOptions) {
        state.hasError = action.payload;
      } else {
        state.hasError = false;
      }
    },
    setCheckedNoCabinBaggage(state, action) {
      state.checkedNoCabinBaggage = action.payload;
      if (action.payload) {
        state.checkedCabinBaggage = false;
      }
    },
    setCheckedCabinBaggage(state, action) {
      state.checkedCabinBaggage = action.payload;
      if (action.payload) {
        state.checkedNoCabinBaggage = false;
      }
    },
    setEmptyAncillaryOptions(state, action) {
      state.emptyAncillaryOptions = action.payload;
    },
    setIsCabinBaggageSelected(state, action) { 
      state.isCabinBaggageSelected = action.payload; 
    },
  },
});

export const {
  setError,
  setCheckedNoCabinBaggage,
  setCheckedCabinBaggage,
  setEmptyAncillaryOptions,
  setIsCabinBaggageSelected, 
} = cabinBaggageSlice.actions;

export default cabinBaggageSlice;
