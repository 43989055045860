import "./filters-modal.component.scss";
import ReactDOM from "react-dom";
import { FiltersComponent } from "../filters.component";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LocalStorageService } from "../../../services/local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";

export const FiltersModalComponent = ({
  isFiltersButtonClicked,
  isModalClose,
  performSearch,
  performFilter,
  holidayFullData,
  contentfulButtons,
  contentfulResultsPageResponse,
  contentfulFilters,
  defaultSearchUsed,
}) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [destinationLocations, setDestinationLocations] = useState(null);
  const [initialDestination, setInitialDestination] = useState(null);

  useEffect(() => {
    setInitialDestination(
      LocalStorageService.getLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS
      )
    );
  }, []);

  useEffect(() => {
    setDestinationLocations(
      holidayFullData?.data?.searchInfo?.destinationLocationCode
    );
    setIsOpen(isFiltersButtonClicked);

    if (isClickedOutside) {
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.destinationLocation,
          holidayFullData?.data?.searchInfo?.destinationLocationCode
        );
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set(searchParamsEnum.destinationLocation, destinationLocations);
        return params;
      });
    }
    setIsClickedOutside(false);
  }, [isFiltersButtonClicked, isOpen]);

  const Backdrop = () => {
    return <div className="backdrop" ref={containerRef} />;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && containerRef.current.contains(event.target)) {
        setIsClickedOutside(true);
        setIsOpen(false);
        isModalClose(true);
        setSearchParams((params) => {
          params.set(searchParamsEnum.departureLocation, initialDestination);
          return params;
        });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const handleOnClose = () => {
    setIsOpen(false);
    isModalClose(true);
  };

  return (
    isOpen && (
      <>
        {ReactDOM.createPortal(
          <div className="side-filter-bar-modal">
            <FiltersComponent
              className="filter-bar-modal-component"
              contentfulButtons={contentfulButtons}
              contentfulFilters={contentfulFilters}
              contentfulResultsPageResponse={contentfulResultsPageResponse}
              holidayFullData={holidayFullData}
              performSearch={performSearch}
              performFilter={performFilter}
              handleOnClose={handleOnClose}
              defaultSearchUsed={defaultSearchUsed}
            />
          </div>,
          document.getElementById("overlay-root")
        )}
        {ReactDOM.createPortal(
          <Backdrop onConfirm={true} />,
          document.getElementById("backdrop-root")
        )}
      </>
    )
  );
};
