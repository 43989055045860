import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as FlightIcon } from "../../assets/icons/klm/icon-flight.svg";
import { ReactComponent as FlightIconTransavia } from "../../assets/icons/transavia/icon-flight-transavia.svg";
import {affiliates} from "../../common/enums/affiliates";
import { useEffect, useState } from "react";

export const FlightComponent = ({
  data,
  showFlightIcon,
  showTitle,
  contentfulHolidayPageSummary,
}) => {

  const [flightInfo, setFlightInfo] = useState(null);

  useEffect(() => {
    setFlightInfo(data)
  },[data]);

  const renderIcon = () => {
    const affiliate = GetAffiliate();

    if (showFlightIcon) {
      if (affiliate === affiliates.klm) {
        return <FlightIcon />;
      } else if (affiliate === affiliates.transavia) {
        return <FlightIconTransavia />;
      }
    }
    return null;
  };

  return (
    <div className="flight-component">
      <div className="flight-component-header">
        {renderIcon()}
        {showTitle
          ? contentfulHolidayPageSummary &&
            contentfulHolidayPageSummary[0]?.fields?.flights
          : ""}
      </div>
      <div className="section-header">
        {flightInfo?.departureAirportCode} - {flightInfo?.arrivalAirportCode}{" "}
        {flightInfo?.directFlight ?
            (contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.directFlight)
        : ((contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.indirectFlight))
        }
      </div>
      <div>{flightInfo?.flightDateTime} <span className="days-later-arrival">{flightInfo?.daysLaterArrivalDescription}</span></div>
      {GetAffiliate() === affiliates.klm ? (
        <div className="flight-cabin-class">{flightInfo?.cabinClass + " class"}</div>
      ) : (
        ""
      )}
    </div>
  );
};
