import React, {useCallback, useEffect, useRef, useState} from "react";
import {GoogleMap, useJsApiLoader, StreetViewPanorama, MarkerF } from "@react-google-maps/api";
import './hotel-map.component.scss'
import "./../../common/colours.scss";
import config from './../../environments/config.json'
import {breakpoints} from "../../common/constants/breakpoints";
import {useWindowSizeDetector} from "../../hooks/useWindowSizeDetector";
import {HotelMapMarkerComponent} from "./marker/hotel-map-marker.component";
import { StaticMapComponent } from "../static-map/static-map.component";

const HotelMapComponent = ({contentfulHolidayPage, hotelPosition}) => {
    const containerRef = useRef(null);
    const mapRef = useRef(null);
    const [fullScreen, setFullScreen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showStaticMap, setShowStaticMap] = useState(true);
    const windowSizeDetector = useWindowSizeDetector();

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.GM_KEY,
        language: 'nl',
        region: 'NL'
    });

    const options = {
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: window?.google?.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window?.google?.maps.ControlPosition.BOTTOM_LEFT,
        },
        controlSize: 24,
        streetViewControl:true
    };

    useEffect(() => {
        windowSizeDetector.width < breakpoints.SM
            ? setIsMobile(true)
            : setIsMobile(false);
    },[windowSizeDetector.width])

    useEffect(() => {
        const handleFullScreenChange = () => {
            setFullScreen(
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            );
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    const enterFullScreen = useCallback(() => {
        const container = containerRef.current;
        switch (true) {
            case !!container.requestFullscreen:
                container.requestFullscreen();
                break;
            case !!container.mozRequestFullScreen:
                container.mozRequestFullScreen();
                break;
            case !!container.webkitRequestFullscreen:
                container.webkitRequestFullscreen();
                break;
            case !!container.msRequestFullscreen:
                container.msRequestFullscreen();
                break;
            default:
                container.requestFullscreen();
                break;
        }
    }, [containerRef]);

    const exitFullScreen = useCallback(() => {
        switch (true) {
            case !!document.exitFullscreen:
                document.exitFullscreen();
                break;
            case !!document.mozCancelFullScreen:
                document.mozCancelFullScreen();
                break;
            case !!document.webkitExitFullscreen:
                document.webkitExitFullscreen();
                break;
            case !!document.msExitFullscreen:
                document.msExitFullscreen();
                break;
            default:
                document.exitFullscreen();
                break;
        }
    }, [fullScreen]);

    const handleFullScreenClick = useCallback(() => {
        if (!fullScreen) {
            enterFullScreen();
        } else {
            exitFullScreen();
        }
    }, [fullScreen, enterFullScreen, exitFullScreen]);

    const screenSizeMapStyle = (() => {
        if (fullScreen) {
            return "holiday-map-container-full-screen-style";
        }
        if (isMobile) {
            return "holiday-map-container-mobile-style"
        }
        return "holiday-map-container-style";
    })();

    const onMapClickChange = () => {
        setShowStaticMap(!showStaticMap)
    }

    return (
        <div className="hotel-map-component" >
            {!fullScreen && 
            <span className="location-map-description"> {contentfulHolidayPage && contentfulHolidayPage[0]?.fields?.location} </span>}
            {isLoaded && !showStaticMap && (
                <div
                    ref={containerRef}
                    style={{
                        position: fullScreen ? 'absolute' : 'relative',
                        width: isMobile? '100vw': '342px',
                        height: '228px',
                        visibility: fullScreen ? 'visible' : 'inherit',
                    }}
                >
                    <GoogleMap
                        mapContainerClassName={screenSizeMapStyle}
                        zoom={12}
                        center={hotelPosition}
                        options={options}
                        onLoad={(map) => {
                            mapRef.current = map;
                        }}
                        streetViewControl={false}
                    >
                        <MarkerF
                            position={hotelPosition}
                            icon={{
                                url: HotelMapMarkerComponent(),
                            }}
                        />

                        <div className={`full-screen-map ${!fullScreen ? 'full-screen-map-small' 
                                    : 'full-screen-map-medium'}`}>
                            <button
                                className={`${!fullScreen ? 'full-screen-map-button full-screen-map-button-small' 
                                    : 'full-screen-map-button-close full-screen-map-button-close-medium'}`}
                                onClick={handleFullScreenClick}></button>
                        </div>
                            <StreetViewPanorama/>
                    </GoogleMap>
                </div>
            )}
            { showStaticMap && 
            <div onClick={onMapClickChange} >
                <StaticMapComponent hotelPosition={hotelPosition}/>
            </div>}
        </div>
    );
};

export default HotelMapComponent;
