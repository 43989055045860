import React from "react";
import { useSelector } from "react-redux";
import ErrorIcon from "../../../assets/icons/transavia/error-trip-message.svg";

const CabinErrorComponent = ({ luggageData }) => {
  const hasError = useSelector((state) => state.cabinBaggage.hasError);
  return (
    <>
      {hasError && (
        <div className="cabin-error-container">
          <img src={ErrorIcon} alt="error message" />
          <p>{luggageData && luggageData[0]?.fields?.noSelectedErrorMessage}</p>
        </div>
      )}
    </>
  );
};

export default CabinErrorComponent;
