import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEmptyAncillaryOptions } from "../../../store/cabinBaggageSlice/slice";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Alert } from "@mui/material";
import { CabinBaggageCard } from "./cabin-baggage-card.component";
import "./cabin-luggage.component.scss";
import { HandBaggageCard } from "./hand-baggage-card.component";
import { BaggageComponent } from "../baggage/baggage.component";
import CabinTripComponent from "./cabin-trip-baggage";

export const CabinLuggage = ({
  contentfulLuggageSelection,
  baggageDetails,
  setIsWaitingForNetwork,
  isWaitingForNetwork,
  contentfulCheckoutPassengerDetails,
  onBaggageSelectionChanged,
  onCabinBaggageSelectionChanged,
  contentfulExtrasPage,
  isLoading,
  loadingPassengerId,
}) => {
  const [cabinBaggageChecked, setCabinBaggageChecked] = useState(
    baggageDetails.showSelectedCabinBaggage
  );

  const dispatch = useDispatch();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="cabin-luggage-description-paragraph">{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  const allAncillaryOptionsEmpty = () => {
    return typeof cabinBaggageOptions === null;
  };

  useEffect(() => {
    dispatch(setEmptyAncillaryOptions(allAncillaryOptionsEmpty()));
  }, []);

  return (
    <div className="cabin-luggage-container">
      <div className="cabin-luggage-information">
        {baggageDetails.showCabinBaggageInfo && (
          <>
            <Alert className="cabin-luggage-description" severity="info">
              {contentfulLuggageSelection &&
                documentToReactComponents(
                  contentfulLuggageSelection[0]?.fields?.disclaimer,
                  options
                )}
            </Alert>
            <HandBaggageCard luggageData={contentfulLuggageSelection} />
            <CabinBaggageCard
              luggageData={contentfulLuggageSelection}
              baggageDetails={baggageDetails}
              allAncillaryOptionsEmpty={allAncillaryOptionsEmpty()}
              onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
              cabinBaggageChecked={cabinBaggageChecked}
              setCabinBaggageChecked={setCabinBaggageChecked}
            />
            <CabinTripComponent
              luggageData={contentfulLuggageSelection}
              baggageDetails={baggageDetails}
              onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
              contentfulExtrasPage={contentfulExtrasPage}
              cabinBaggageChecked={cabinBaggageChecked}
              setCabinBaggageChecked={setCabinBaggageChecked}
            />
          </>
        )}
        {baggageDetails.showBaggageOptions && (
          <BaggageComponent
            setIsWaitingForNetwork={setIsWaitingForNetwork}
            isWaitingForNetwork={isWaitingForNetwork}
            contentfulCheckoutPassengerDetails={
              contentfulCheckoutPassengerDetails
            }
            contentfulExtrasPage={contentfulExtrasPage}
            onBaggageSelectionChanged={onBaggageSelectionChanged}
            baggageDetails={baggageDetails}
            isLoading={isLoading}
            loadingPassengerId={loadingPassengerId}
          />
        )}
      </div>
    </div>
  );
};
